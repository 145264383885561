/**
 * @flow
 */
import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import media from "../../styles/media"

import placeholder from "../../images/icons/profile_white.svg"
import icon from "./images/vote-icon.png"

type Props = {
  slug: string,
  name: string,
  votes: number,
  position: number,
  imgId: string,
}

const Container = styled.div`
  display: grid;
  grid-template: auto / 50px 1fr 100px;
  grid-gap: 0.5rem;
  margin-bottom: 0.5rem;
  ${media.tablet`
    grid-template: auto / 50px 50px 1fr 100px;
  `}
`

const Column = styled.div`
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  font-family: "AvenirLTStdBlack";
  font-size: 0.9rem;
  min-height: 50px;
  &.position {
    justify-content: center;
  }
  &.picture {
    object-fit: cover;
    display: none;
  }
  &.name {
    padding: 0 1rem;
    text-transform: uppercase;
  }
  &.votes {
    justify-content: center;
    img {
      margin-right: 10px;
      height: 15px;
      width: auto;
    }
  }
  ${media.tablet`
    &.picture {
      display: flex;
      padding: 0.25rem;
      img {
        object-fit: cover;
        width: calc(50px - 0.5rem);
        height: calc(50px - 0.5rem);
        object-fit: center;
        border-radius: 50%;
      }
    }
  `}
`

const Leader = ({ imgId, slug, name, votes, position }: Props) => {
  const onImgError = e => {
    const target = e.target
    target.src = `${placeholder}`
  }

  return (
    <Container>
      <Column className="position"> {position} </Column>
      <Column className="picture">
        <img
          src={encodeURI(
            `${process.env.S3_BUCKET_BASE_URL}${imgId}/${slug}.jpg`
          )}
          alt={`Sound Idea Sessions | ${name}`}
          onError={onImgError}
        />
      </Column>
      <Column className="name">
        <Link to={`/profiles/${slug}`} className="mouse-link">
          {name}
        </Link>
      </Column>
      <Column className="votes">
        <img src={icon} alt="Sound Idea Sessions | Leaderboard" />
        {votes}
      </Column>
    </Container>
  )
}

Leader.defaultProps = {
  name: "Luan van der Westhuizen",
  votes: 175,
  position: 1,
}

export default Leader
