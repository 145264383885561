import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import LeaderboardPage from "../components/LeaderboardPage"

const Leaderboard = ({ data }) => {
  const menuItems = [
    // { name: "Sign Up", path: "/sign-up" },
    // { name: "Profiles", path: "/profiles" },
  ]
  return (
    <>
      <SEO title="Leaderboard" />
      <Navbar isGHP back items={menuItems} topOffset={0} bottomOffset={125} />
      <LeaderboardPage data={data} />
      <Footer back />
    </>
  )
}

export const query = graphql`
  query Leaderboard {
    sisapi {
      profiles {
        edges {
          node {
            id
            givenName
            familyName
            voteCount
            status
            owner {
              cognitoIdentityId
            }
          }
        }
      }
    }
  }
`

Leaderboard.propTypes = {
  data: PropTypes.object,
}

export default Leaderboard
