import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"

import PageHeader from "../elements/PageHeader"
import Leader from "./Leader"

import media from "../../styles/media"

import underline_v1 from "../../images/Auth/underline_one.png"

const Container = styled.div`
  background-color: rgba(196, 18, 48, 0.99);
  padding-left: var(--gutter-s);
  padding-bottom: 10vh;
  .intro,
  .leaders,
  .link {
    margin-right: var(--gutter-s);
  }
  .intro {
    margin-top: 4rem;
    margin-bottom: 4rem;
    p {
      font-size: 1.1rem;
      line-height: 2rem;
    }
  }
  .leaders {
    position: relative;
    h1 {
      color: rgba(255, 255, 255, 0.95);
      font-family: "AvenirLTStdBlack";
      font-size: 4rem;
    }
  }
  .link {
    padding-top: 2rem;
    text-align: center;
    a {
      font-family: "AvenirLTStdMedium";
      font-size: 1.1rem;
      display: inline-block;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: calc(100% + 20px);
        height: 100%;
        left: -10px;
        bottom: 0;
        transform: translateY(100%);
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-image: url(${underline_v1});
      }
    }
  }
  ${media.tablet`
    padding-left: 10vw;
    .intro,
    .link {
      margin-right: 5vw;
    }
    .leaders {
      margin-right: 20vw;
      h1 {
        position: absolute;
        writing-mode: vertical-rl;
        top: 0;
        right: 0;
        transform: translateX(100%);
        font-size: 5rem;
      }
    }
  `}
  ${media.laptop`
    padding-left: 25vw;
    .intro,
    .leaders,
    .link {
      margin-right: 20vw;
    }
    .leaders {
      h1 {
        font-size: 8rem;
      }
    }
  `}
`

const LeaderboardPage = ({ data }) => {
  const leaders = data.sisapi.profiles.edges.sort((a, b) => {
    return b.node.voteCount - a.node.voteCount
  })

  const renderIntro = () => {
    if (leaders.length > 0) {
      return (
        <p>
          Here are your top Growth Hacker Profiles as voted for by you! Votes
          are verified everyday, be sure to check back regularly to witness your
          growth
        </p>
      )
    } else {
      return (
        <p>
          Profiles are currently being approved, be sure to check back soon for
          the updated leaderboard.
        </p>
      )
    }
  }

  const renderHeading = () => {
    if (leaders.length > 19) {
      return <h1>LEADERS</h1>
    }
  }

  const renderLeaders = () => {
    if (leaders) {
      let count = 0
      let votes = 0
      return leaders.slice(0, 20).map(leader => {
        if (leader.node.status != "APPROVED") {
          return null
        }
        if (leader.node.voteCount != votes) {
          count += 1
        }
        votes = leader.node.voteCount
        return (
          <Leader
            key={leader.node.id}
            slug={leader.node.id}
            name={`${leader.node.givenName} ${leader.node.familyName}`}
            votes={leader.node.voteCount}
            position={count}
            imgId={leader.node.owner.cognitoIdentityId}
          />
        )
      })
    }
  }

  return (
    <>
      <Container>
        <PageHeader leftIndent />
        <div className="intro"> {renderIntro()} </div>
        <div className="leaders">
          {renderHeading()}
          {renderLeaders()}
        </div>
        <div className="link">
          <Link to="/profiles" className="mouse-link">
            All profiles
          </Link>
        </div>
      </Container>
    </>
  )
}

LeaderboardPage.propTypes = {
  data: PropTypes.object,
}

export default LeaderboardPage
